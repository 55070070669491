<template>
  <div class="clebex-calendar field-selection" v-if="showDatePicker">
    <header class="calendar-header">
      <div class="calendar-title">
        {{ cal.parsedDateMonth() }} <span>{{ cal.parsedDateYear() }}</span>
      </div>
      <div class="month-switcher icon-app1">
        <button
          class="month prev"
          data-skip
          type="button"
          @click="cal.prevMonth()"
        >
          <icon icon="#cx-app1-arrow-left" />
        </button>
        <button class="month current" type="button" @click="selectToday">
          <icon icon="#cx-app1-arrow-today"></icon>
        </button>
        <button
          class="month prev"
          data-skip
          type="button"
          @click="cal.nextMonth()"
        >
          <icon icon="#cx-app1-arrow-right" />
        </button>
      </div>
    </header>
    <div class="calendar-wrapper" ref="calendarWrapper">
      <ul class="days-of-the-week-labels">
        <li
          class="day-label"
          v-for="day in cal.labelsOfDaysWholeMonth()"
          v-bind:key="day"
        >
          {{ day }}
        </li>
      </ul>
      <ul class="days-of-the-week">
        <li
          class="day"
          v-for="day in cal.numbersOfDaysToDisplayWholeMonth()"
          :class="[
            {
              today: cal.isToday(day.dateString),
              selected: day.dateString === selectedDate
            },
            day.occupancy ? `occupancy ${day.occupancy}` : ''
          ]"
          v-bind:key="day"
        >
          <span class="day-button disabled" v-if="day.outOfRange">
            {{ day.dayNum }}
          </span>
          <button
            v-else
            data-skip
            type="button"
            class="day-button"
            @click="selectDate(day.dateObj)"
          >
            {{ day.dayNum }}
          </button>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import ClebexCalendar from "@/functionalties/calendar";
import dayjs from "dayjs";

export default {
  name: "SelectDate",
  data() {
    return {
      cal: Object.create(ClebexCalendar),
      selectedDateAlreadySet: false
    };
  },
  created() {
    this.init();
  },
  updated() {
    this.init();
  },
  methods: {
    init() {
      const { selectedDate, disablePreviousDays, cyclicYear } = this.$props;
      if (cyclicYear) {
        this.cal.cyclicYear = true;
      }
      if (disablePreviousDays)
        this.cal.disablePreviousDays = disablePreviousDays;
      if (
        selectedDate &&
        !this.cal.selectedDate &&
        !this.selectedDateAlreadySet
      ) {
        if (!this.dateFormat) {
          const length = selectedDate.split(" ").length;
          let dateString = "";
          if (length <= 2) {
            dateString = `${new Date().getFullYear()}-${selectedDate}`;
          } else {
            dateString = selectedDate;
          }
          const date = dayjs(dateString);
          this.cal.setSelectedYear(date.year());
          this.cal.setSelectedMonth(date.month() + 1);
          this.cal.selectDate(date);
        } else {
          if (this.dateFormat === "YYYY-MM-DD") {
            const date = dayjs(this.selectedDate);
            this.cal.setSelectedYear(date.year());
            this.cal.setSelectedMonth(date.month() + 1);
            this.cal.selectDate(date);
          }
        }
        this.selectedDateAlreadySet = true;
      } else {
        if (this.selectedDateAlreadySet) {
          this.selectedDateAlreadySet = !this.selectedDateAlreadySet;
        }
      }
    },
    selectDate(date) {
      this.cal.selectDate(date);
      this.selectDateFn(
        dayjs(date).format(this.dateFormat || "MM-DD"),
        dayjs(date).format(this.displayDateFormat || "D MMMM")
      );
    },
    selectToday() {
      if (this.disableSelectToday) {
        return;
      }
      const today = dayjs().startOf("day");
      const monthIndex = today.month();
      this.cal.selectDate(today);
      this.selectDateFn(
        dayjs(today).format(this.dateFormat || "MM-DD"),
        dayjs(today).format(this.displayDateFormat || "D MMMM")
      );
      if (this.cal.month() !== monthIndex) {
        this.cal.setSelectedMonth(monthIndex);
      }
    }
  },
  watch: {
    dateToWatch() {
      if (!this.dateToWatch) {
        this.cal.selectDate(null);
      }
    }
  },
  props: {
    showDatePicker: {
      type: Boolean,
      required: false,
      default: false
    },
    selectDateFn: {
      type: Function,
      required: true
    },
    dateFormat: {
      type: String,
      required: false
    },
    displayDateFormat: {
      type: String,
      required: false
    },
    dateToWatch: {
      type: [String, Boolean],
      required: true
    },
    selectedDate: {
      type: [String, Boolean],
      required: false
    },
    disablePreviousDays: {
      type: Boolean,
      required: false
    },
    cyclicYear: {
      type: Boolean,
      required: false
    },
    disableSelectToday: {
      type: Boolean,
      required: false
    }
  }
};
</script>
